import React from "react";
import MaterialTable from "material-table";
import './App.css';

function App() {
	return (
		<div style={{ maxWidth: "100%" }}>
		<MaterialTable
		columns={[
			{ title: 'Тил/Язык', field: 'lang' },
			{ title: 'Класс', field: 'grade' },
			{ title: 'Түрү/Вид', field: 'type'},
			{ title: 'Курс', field: 'course' },
			{ title: 'Аталышы/Название', field: 'material_name' },
			{ title: 'Шилтеме/Ссылка', field: 'material', render: rowData => <a href={rowData.material}>Скачать</a>},
		]}
		data={[
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/c9eb7853dfa0ba492b6a9b97a6396946/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_1.3._%D0%9A%D0%9F_%D0%A2%D0%B5%D0%BA%D1%81%D1%82%D1%82%D0%B8%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/8e020dcd1a4c269eb891d0ddc2cb6647/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80_%D0%BA%D0%BF_.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Презентациялар', 	material: 'https://eduhub.kg/assets/courseware/v1/e72719f87e6f618b1349dcf44c3ba166/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.3.%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80_%D0%BA%D0%BF_.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издөө суроо талаптары', 	material: 'https://eduhub.kg/assets/courseware/v1/80e6dee1676ff5afa039fdbf25027f04/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.1._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D0%B8%D0%B7%D0%B4%D3%A9%D3%A9_%D1%81%D1%83%D1%80%D0%BE%D0%BE-%D1%82%D0%B0%D0%BB%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D1%8B.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайттардын конструкторлору', 	material: 'https://eduhub.kg/assets/courseware/v1/67a7c7d2d78d409148aea667c32902e5/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.2._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%A1%D0%B0%D0%B9%D1%82_%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80%D1%83.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Электрондук почта жана булуттар сервистери', 	material: 'https://eduhub.kg/assets/courseware/v1/86421398b419e7b503501a4bc9ee7117/asset-v1:KAI+INF88+01092020+type@asset+block/7-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.3._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%AD%D0%9F_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B1%D1%83%D0%BB%D1%83%D1%82%D1%82%D1%83%D0%BA_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%82%D0%B5%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Компьютер адамдын турмушунда', 	material: 'https://eduhub.kg/assets/courseware/v1/80c3e457ff5d888f5c0e55ec2c84694e/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B0%D0%B4%D0%B0%D0%BC%D0%B4%D1%8B%D0%BD_%D0%B6%D0%B0%D1%88%D0%BE%D0%BE%D1%81%D1%83%D0%BD%D0%B4%D0%B0.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык процесстер', 	material: 'https://eduhub.kg/assets/courseware/v1/cde5cd6152a97097c007aaea228de6fe/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2._%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%82%D0%B5%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D1%81%D0%B0%D0%BA%D1%82%D0%BE%D0%BE.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/8e4ae8a69ba22d1adf3ead71b3c817e0/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3.%D0%A2%D0%B5%D0%BA%D1%81%D1%82%D1%82%D0%B8%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Программалык камсыздоонун түрлөрү жана курамы', 	material: 'https://eduhub.kg/courses/course-v1:KAI+INF86+01092020/9283ef96faea4daaa5cc65c48f05cf5b/eduhub.kg/static/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.1.%D0%9F%D0%9A_%D0%BA%D1%83%D1%80%D0%B0%D0%BC%D1%8B_%D0%B6%D0%B0%D0%BD%D0%B0_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF_1_.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/7%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%202_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA%20%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Презентациялар', 	material: 'https://eduhub.kg/assets/courseware/v1/57878f534449443b4f1df07973d58331/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.3.%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Python программалоо тили', 	material: '' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Маалыматтардын тиби жана алар менен болгон амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/71a280a36f4080bf0658cf10cb264e85/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D0%B0%D1%80%D0%B4%D1%8B%D0%BD_%D1%82%D0%B8%D0%B1%D0%B8_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BB%D0%B0%D1%80_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B1%D0%BE%D0%BB%D0%B3%D0%BE%D0%BD_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Шарттуу операторлор', 	material: 'https://eduhub.kg/assets/courseware/v1/b0016391772bf21bbb6bea3e387ecb25/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.3.%D0%A8%D0%B0%D1%80%D1%82%D1%82%D1%83%D1%83_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'while жана for циклдери', 	material: 'https://eduhub.kg/assets/courseware/v1/f286dad2d6b69285b5f0371a176698ee/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.4.while_%D0%B6%D0%B0%D0%BD%D0%B0_for_%D1%86%D0%B8%D0%BA%D0%BB%D0%B4%D0%B5%D1%80%D0%B8.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издөө суроо талаптары', 	material: 'https://eduhub.kg/assets/courseware/v1/a8c197016ac27b437412c05cd67ad202/asset-v1:KAI+INF88+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D0%B8%D0%B7%D0%B4%D3%A9%D3%A9_%D1%81%D1%83%D1%80%D0%BE%D0%BE-%D1%82%D0%B0%D0%BB%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D1%8B.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайттардын конструкторлору', 	material: 'https://eduhub.kg/assets/courseware/v1/97e68cfaae2a9eaef1cfb1a78277bc22/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%A1%D0%B0%D0%B9%D1%82_%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80%D1%83.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Электрондук почта жана булуттар сервистери', 	material: 'https://eduhub.kg/assets/courseware/v1/d3ab7bda6633188ad12f024e7a41640f/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.3_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B1%D1%83%D0%BB%D1%83%D1%82%D1%82%D1%83%D0%BA_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%82%D0%B5%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Компьютер адамдын турмушунда', 	material: 'https://eduhub.kg/assets/courseware/v1/12fbeb4367128cf19a557c5ba467838a/asset-v1:KAI+INF85+01052021+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B0%D0%B4%D0%B0%D0%BC%D0%B4%D1%8B%D0%BD_%D0%B6%D0%B0%D1%88%D0%BE%D0%BE%D1%81%D1%83%D0%BD%D0%B4%D0%B0.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык процесстер', 	material: 'https://eduhub.kg/assets/courseware/v1/cd21922a8ff52d642b5b970d5eb609d8/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%82%D0%B5%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D1%81%D0%B0%D0%BA%D1%82%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/badbb5a9d2ca4d167cd5cbf70e7d37b7/asset-v1:KAI+INF85+01052021+type@asset+block/7_-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3.%D0%A2%D0%B5%D0%BA%D1%81%D1%82%D1%82%D0%B8%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Программалык камсыздоонун түрлөрү жана курамы', 	material: 'https://eduhub.kg/assets/courseware/v1/63ce594576cbd9a5fd32b330c8a2e283/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BA%D0%B0%D0%BC%D1%81%D1%8B%D0%B7%D0%B4%D0%BE%D0%BE%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BA%D1%83%D1%80%D0%B0%D0%BC%D1%8B.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/28c0f97ad5a8cb215420dcabdfd48958/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Презентациялар', 	material: 'https://eduhub.kg/assets/courseware/v1/ec1a7573450d63b5a18182e76d681cb3/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.3.%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Python программалоо тили', 	material: 'https://eduhub.kg/assets/courseware/v1/033338dadd5eae7d44d18d90fea4ce76/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.1.Python_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BB%D0%BE%D0%BE_%D1%82%D0%B8%D0%BB%D0%B8.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Маалыматтардын тиби жана алар менен болгон амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/ece073d14231eeaf8fda258999b6c5ce/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.2.%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D0%B0%D1%80%D0%B4%D1%8B%D0%BD_%D1%82%D0%B8%D0%B1%D0%B8_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BB%D0%B0%D1%80_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B1%D0%BE%D0%BB%D0%B3%D0%BE%D0%BD_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Шарттуу операторлор', 	material: 'https://eduhub.kg/assets/courseware/v1/28eadf82929655553d3c7473775d4f59/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.3.%D0%A8%D0%B0%D1%80%D1%82%D1%82%D1%83%D1%83_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'while жана for циклдери', 	material: 'https://eduhub.kg/assets/courseware/v1/2e58b127638db66983e49a3a7413c762/asset-v1:KAI+INF87+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.4.While_%D0%B6%D0%B0%D0%BD%D0%B0_for_%D1%86%D0%B8%D0%BA%D0%BB%D0%B4%D0%B5%D1%80%D0%B8.docx' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издөө суроо талаптары', 	material: 'https://eduhub.kg/assets/courseware/v1/56323a1bb8063fad3dd68c269cda5fbb/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D0%B8%D0%B7%D0%B4%D3%A9%D3%A9_%D1%81%D1%83%D1%80%D0%BE%D0%BE_%D1%82%D0%B0%D0%BB%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайттардын конструкторлору', 	material: 'https://eduhub.kg/assets/courseware/v1/1567935b603d3f59c2248fc526efb745/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%A1%D0%B0%D0%B9%D1%82_%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Электрондук почта жана булуттар сервистери', 	material: 'https://eduhub.kg/assets/courseware/v1/d4ec6a9e7b506b98c051153985cbd07b/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3._%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B1%D1%83%D0%BB%D1%83%D1%82%D1%82%D1%83%D0%BA_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%82%D0%B5%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Компьютер адамдын турмушунда', 	material: 'https://eduhub.kg/assets/courseware/v1/50845afdeb30d1dfc50956dbc0d214f3/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%A3%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE._%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B0%D0%B0%D0%B4%D0%B0%D0%BC%D0%B4%D1%8B%D0%BD_%D0%B6%D0%B0%D1%88%D0%BE%D0%BE%D1%81%D1%83%D0%BD%D0%B4%D0%B0_%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык процесстер', 	material: 'https://eduhub.kg/assets/courseware/v1/1d0f2dc0bc0b38c3a824bf40e36b63d4/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2_%D0%A3%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/f5ca7307b9d8b8800bd7eb4213c95a38/asset-v1:KAI+INF85+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3_%D1%83%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Программалык камсыздоонун түрлөрү жана курамы', 	material: 'https://eduhub.kg/assets/courseware/v1/fb6c28a84e72532fa61f050a2057d54e/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BA%D0%B0%D0%BC%D1%81%D1%8B%D0%B7%D0%B4%D0%BE%D0%BE%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BA%D1%83%D1%80%D0%B0%D0%BC%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/5521dc31766e9b61fe4043e18671b728/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Презентациялар', 	material: 'https://eduhub.kg/assets/courseware/v1/ec1a7573450d63b5a18182e76d681cb3/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.3.%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издөө суроо талаптары', 	material: 'https://eduhub.kg/assets/courseware/v1/afd52543cd76008baff6e414ef56188c/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D1%82%D0%B0%D1%80%D0%BC%D0%B0%D0%BA%D1%82%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайттардын конструкторлору', 	material: 'https://eduhub.kg/assets/courseware/v1/41bd201562ac7c4cf784826bdc3f1ea9/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%A1%D0%B0%D0%B9%D1%82_%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80%D1%83.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Электрондук почта жана булуттар сервистери', 	material: 'https://eduhub.kg/assets/courseware/v1/c0cedd0df44a0431ec5c30c2bff11767/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B1%D1%83%D0%BB%D1%83%D1%82%D1%82%D1%83%D0%BA_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%82%D0%B5%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Компьютер адамдын турмушунда', 	material: 'https://eduhub.kg/assets/courseware/v1/a7066fb6e39a46880b0c3c49d39d4edf/asset-v1:KAI+INF85+01052021+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.1%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B0%D0%B4%D0%B0%D0%BC%D0%B4%D1%8B%D0%BD_%D1%82%D1%83%D1%80%D0%BC%D1%83%D1%88%D1%83%D0%BD%D0%B4%D0%B0.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык процесстер', 	material: 'https://eduhub.kg/assets/courseware/v1/aae5c18410e08795b35cf6723c99c034/asset-v1:KAI+INF85+01052021+type@asset+block/7_-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.2%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%82%D0%B5%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D1%81%D0%B0%D0%BA%D1%82%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/baa3958d16083b3e3aee1d8375e6c8e2/asset-v1:KAI+INF85+01052021+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.3.%D0%A2%D0%B5%D0%BA%D1%81%D1%82%D1%82%D0%B8%D0%BA_%D0%B4%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%82%D0%B8_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Программалык камсыздоонун түрлөрү жана курамы', 	material: 'https://eduhub.kg/assets/courseware/v1/fb6c28a84e72532fa61f050a2057d54e/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BA%D0%B0%D0%BC%D1%81%D1%8B%D0%B7%D0%B4%D0%BE%D0%BE%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BA%D1%83%D1%80%D0%B0%D0%BC%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/5521dc31766e9b61fe4043e18671b728/asset-v1:KAI+INF86+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%B4%D1%83%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издөө суроо талаптары', 	material: 'https://eduhub.kg/assets/courseware/v1/61e2888c8ad8452b6f633f32087c9715/asset-v1:KAI+INF88+01092020+type@asset+block/7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.1.%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D0%B8%D0%B7%D0%B4%D3%A9%D3%A9_%D1%81%D1%83%D1%80%D0%BE%D0%BE-%D1%82%D0%B0%D0%BB%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайттардын конструкторлору', 	material: 'https://eduhub.kg/assets/courseware/v1/2f368347a1165cb9d4abb8016d0c2c32/asset-v1:KAI+INF88+01092020+type@asset+block/7-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.2.%D0%A1%D0%B0%D0%B9%D1%82_%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D0%BB%D0%BE%D1%80%D1%83.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдордун түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/6310f4bf7ebf3f593a569e92bcd0e47a/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.2._%D0%9A%D0%9F_%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82_%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D1%80%D1%83%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Компьютердик практикум', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Стилдердин каскаддык таблицалары (СSS)', 	material: 'https://eduhub.kg/assets/courseware/v1/fa6fd5eac323e56d9b9eb81f5a550915/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.3._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D1%81%D1%82%D0%B8%D0%BB%D0%B4%D0%B5%D1%80%D0%B4%D0%B8%D0%BD_%D0%BA%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%B4%D1%8B%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80%D1%8B.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмалар жана амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/2d93d9a64d281f22d05659c108932bba/asset-v1:KAI+INF85+01052021+type@asset+block/8-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Логиканын мыйзамдары', 	material: 'https://eduhub.kg/assets/courseware/v1/cc415b9e8df3b070f5c1d1205f7810fa/asset-v1:KAI+INF85+01052021+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2_%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BD%D1%8B%D0%BD_%D0%BC%D1%8B%D0%B9%D0%B7%D0%B0%D0%BC%D0%B4%D0%B0%D1%80%D1%8B.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмаларды чечүү', 	material: 'https://eduhub.kg/assets/courseware/v1/5cae6d9ba1b329b7a96ba81eabc99c22/asset-v1:KAI+INF85+01052021+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3_%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80%D0%B4%D1%8B_%D1%87%D1%8B%D0%B3%D0%B0%D1%80%D1%83%D1%83.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'ПК жана лицензиялардын түрлөрү', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%202_%D1%82%D0%B5%D0%BC%D0%B02.1.%D0%9F%D0%9A%20%D0%B6%D0%B0%D0%BD%D0%B0%20%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D1%8F%D0%BD%D1%8B%D0%BD%20%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Маалыматтар базасы', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%202_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D0%B0%D1%80%20%D0%B1%D0%B0%D0%B7%D0%B0%D1%81%D1%8B.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Татаал шарттар: and, or, not.', 	material: 'https://eduhub.kg/assets/courseware/v1/5ce594315720e7b5c2cd2920286e5e87/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.1.%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D1%88%D0%B0%D1%80%D1%82%D1%82%D0%B0%D1%80_and_or_not.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Тизмелер, кортеждер жана сөздүктөр', 	material: 'https://eduhub.kg/assets/courseware/v1/21d14f53abdb8835e88bd6149b1f87fd/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%A2%D0%B8%D0%B7%D0%BC%D0%B5%D0%BB%D0%B5%D1%80__%D0%BA%D0%BE%D1%80%D1%82%D0%B5%D0%B6%D0%B4%D0%B5%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D1%81%D3%A9%D0%B7%D0%B4%D2%AF%D0%BA%D1%82%D3%A9%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Циклдик алгоритмдер менен иштөө', 	material: 'https://eduhub.kg/assets/courseware/v1/521c922527597cd0a1237664275ed978/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.3.%D0%A6%D0%B8%D0%BA%D0%BB%D0%B4%D0%B8%D0%BA_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D0%B4%D0%B5%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Камтылган циклдер', 	material: 'https://eduhub.kg/assets/courseware/v1/18c3cf547e91f4cf33b13c70bd74b1dd/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.4.%D0%9A%D0%B0%D0%BC%D1%82%D1%8B%D0%BB%D0%B3%D0%B0%D0%BD_%D1%88%D0%B0%D1%80%D1%82%D1%82%D1%83%D1%83_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D1%86%D0%B8%D0%BA%D0%BB%D0%B4%D0%B5%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Функциялар', 	material: 'https://eduhub.kg/assets/courseware/v1/c285494107bf268d3ce0052560964541/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.5.%D0%A4%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Массивдер (1 өлчөмдүү массивдер)', 	material: 'https://eduhub.kg/assets/courseware/v1/4af409a563d7dc2d2317f800f2515d1c/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.6.%D0%9C%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%B4%D0%B5%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Символдук саптар жана алар менен болгон амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/2d6f45885c8aa4a0504fbc8055af201d/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.7.%D0%A1%D0%B0%D0%BF%D1%82%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BB%D0%B0%D1%80_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B1%D0%BE%D0%BB%D0%B3%D0%BE%D0%BD_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Саптарды форматтоо', 	material: 'https://eduhub.kg/assets/courseware/v1/614c8d64f8f617d7d75ba33e984e9d6c/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.8.%D0%A1%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D0%B4%D1%8B_%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D1%82%D0%BE%D0%BE.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Python тилинде графика менен иштөө', 	material: 'https://eduhub.kg/assets/courseware/v1/a6a80cd97cc9e012e96c8e38fbecfae2/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.9.Python_%D1%82%D0%B8%D0%BB%D0%B8%D0%BD%D0%B4%D0%B5_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B8%D1%88%D1%82%D3%A9%D3%A9.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Компьютердик тармактар', 	material: 'https://eduhub.kg/assets/courseware/v1/2b14f0da6187c881a2aca243328327ca/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D1%82%D0%B0%D1%80%D0%BC%D0%B0%D0%BA%D1%82%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдордун түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/34d0dd4973d5c7b5fdb16358e98e6a4a/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82_%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D1%80%D1%83%D0%BD%D1%83%D0%BD_%D1%82%D1%83%D1%80%D0%BB%D0%BE%D1%80%D1%83.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Стилдердин каскаддык таблицалары (СSS)', 	material: 'https://eduhub.kg/assets/courseware/v1/e7d20ffe6b2d9a545fc28467cbf37989/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.3._%D0%A1%D1%82%D0%B8%D0%BB%D0%B4%D0%B5%D1%80%D0%B4%D0%B8%D0%BD_%D0%BA%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%B4%D1%8B%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80%D1%8B.pptx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмалар жана амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/69a8757075830ee2f0f9107d00deb6a2/asset-v1:KAI+INF85+01052021+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Логиканын мыйзамдары', 	material: 'https://eduhub.kg/assets/courseware/v1/a53f1f6ed7bd830d99b9977bac0f4558/asset-v1:KAI+INF85+01052021+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BD%D1%8B%D0%BD_%D0%BC%D1%8B%D0%B9%D0%B7%D0%B0%D0%BC%D0%B4%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмаларды чечүү', 	material: 'https://eduhub.kg/assets/courseware/v1/21fa97ab536d1af979ff62296dfca781/asset-v1:KAI+INF85+01052021+type@asset+block/8_-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80%D0%B4%D1%8B_%D1%87%D1%8B%D0%B3%D0%B0%D1%80%D1%83%D1%83.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Татаал шарттар: and, or, not.', 	material: 'https://eduhub.kg/assets/courseware/v1/85ceb3741acdcf47e60f27d7f2f7a5d9/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.1.%D0%A2%D0%B0%D1%82%D0%B0%D0%B0%D0%BB_%D1%88%D0%B0%D1%80%D1%82%D1%82%D0%B0%D1%80_and_or_not.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Тизмелер, кортеждер жана сөздүктөр', 	material: 'https://eduhub.kg/assets/courseware/v1/8e444025b6f0fa57fb218f31a47bbe5b/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.2.%D0%A2%D0%B8%D0%B7%D0%BC%D0%B5%D0%BB%D0%B5%D1%80__%D0%BA%D0%BE%D1%80%D1%82%D0%B5%D0%B6%D0%B4%D0%B5%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D1%81%D3%A9%D0%B7%D0%B4%D2%AF%D0%BA%D1%82%D3%A9%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Циклдик алгоритмдер менен иштөө', 	material: 'https://eduhub.kg/assets/courseware/v1/6f419831d3abed6e812582a99f43b7ae/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.3.%D0%A6%D0%B8%D0%BA%D0%BB%D0%B4%D0%B8%D0%BA_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D0%B4%D0%B5%D1%80_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B8%D1%88%D1%82%D3%A9%D3%A9.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Камтылган циклдер', 	material: 'https://eduhub.kg/assets/courseware/v1/5e3a3d098414e229cf246b6d3d895d58/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.4.%D0%9A%D0%B0%D0%BC%D1%82%D1%8B%D0%BB%D0%B3%D0%B0%D0%BD_%D1%88%D0%B0%D1%80%D1%82%D1%82%D1%83%D1%83_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D1%86%D0%B8%D0%BA%D0%BB%D0%B4%D0%B5%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Функциялар', 	material: 'https://eduhub.kg/assets/courseware/v1/be08e004815c42ad2f0995b1f3278aaa/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.5.%D0%A4%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D1%8F%D0%BB%D0%B0%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Массивдер (1 өлчөмдүү массивдер)', 	material: 'https://eduhub.kg/assets/courseware/v1/25e3a331f818c3c63ef28796a5ef0d55/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.6.%D0%9C%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%B4%D0%B5%D1%80__1_%D3%A9%D0%BB%D1%87%D3%A9%D0%BC%D0%B4%D2%AF%D2%AF_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%B4%D0%B5%D1%80_.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Символдук саптар жана алар менен болгон амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/e9730f96b531556d5650b4fc0e3f4231/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.7.%D0%A1%D0%B0%D0%BF%D1%82%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BB%D0%B0%D1%80_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B1%D0%BE%D0%BB%D0%B3%D0%BE%D0%BD_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Саптарды форматтоо', 	material: 'https://eduhub.kg/assets/courseware/v1/d9025c8a397e658dfb6781fce4e2281e/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.8.%D0%A1%D0%B0%D0%BF%D1%82%D0%B0%D1%80%D0%B4%D1%8B_%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D1%82%D0%BE%D0%BE.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Python тилинде графика менен иштөө', 	material: 'https://eduhub.kg/assets/courseware/v1/2bac448ebf9eeeb2477715e45f84ff73/asset-v1:KAI+INF87+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.9.Python_%D1%82%D0%B8%D0%BB%D0%B8%D0%BD%D0%B4%D0%B5_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0_%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD_%D0%B8%D1%88%D1%82%D3%A9%D3%A9.docx' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Компьютердик тармактар', 	material: 'https://eduhub.kg/assets/courseware/v1/9c0bc377740a3bcf6c68e17e9ebf0203/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D1%82%D0%B0%D1%80%D0%BC%D0%B0%D0%BA%D1%82%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдордун түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/b4f1144b2fe3042636812dfbe9f37d6a/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82_%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D1%80%D1%83%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Стилдердин каскаддык таблицалары (СSS)', 	material: 'https://eduhub.kg/assets/courseware/v1/774b490105d9418bcb17bbae1ee45e06/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3._%D0%A1%D1%82%D0%B8%D0%BB%D0%B4%D0%B5%D1%80%D0%B4%D0%B8%D0%BD_%D0%BA%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%B4%D1%8B%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмалар жана амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/f681172f5211969602fb62c94e1717c2/asset-v1:KAI+INF85+01052021+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D1%83%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE_%D0%93%D0%BB%D0%B0%D0%B2%D0%B01._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82_%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Логиканын мыйзамдары', 	material: 'https://eduhub.kg/assets/courseware/v1/6ca375294224e67c4e3bde4e4f7ac1bc/asset-v1:KAI+INF85+01052021+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2_-_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D1%83%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмаларды чечүү', 	material: 'https://eduhub.kg/assets/courseware/v1/207a1eff815ede79c62b7c38b4f00c7e/asset-v1:KAI+INF85+01052021+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D1%83%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'ПК жана лицензиялардын түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/17ce7bc45ed1a14fc8d817ba00b6d3a6/asset-v1:KAI+INF86+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D0%9A_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D0%B4%D1%8B%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF__1_.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Маалыматтар базасы', 	material: 'https://eduhub.kg/assets/courseware/v1/4932361b76feb9d03cd4ba913ea93e02/asset-v1:KAI+INF86+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D0%B0%D1%80_%D0%B1%D0%B0%D0%B7%D0%B0%D1%81%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Компьютердик тармактар', 	material: 'https://eduhub.kg/assets/courseware/v1/bb53717cbddc25c1de8836e1a07910e8/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D1%82%D0%B0%D1%80%D0%BC%D0%B0%D0%BA%D1%82%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдордун түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/ded5dd36cee32ef0a1e7e32d17986d54/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82_%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D1%80%D1%83%D0%BD%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Стилдердин каскаддык таблицалары (СSS)', 	material: 'https://eduhub.kg/assets/courseware/v1/d8c20167ff41a08b9212f82d87f6c273/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%A1%D1%82%D0%B8%D0%BB%D0%B4%D0%B5%D1%80%D0%B4%D0%B8%D0%BD_%D0%BA%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%B4%D1%8B%D0%BA_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмалар жана амалдар', 	material: 'https://eduhub.kg/assets/courseware/v1/19b35c267a8f79a94b3d09e7c3609570/asset-v1:KAI+INF85+01052021+type@asset+block/8-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.1._%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%B0%D0%BC%D0%B0%D0%BB%D0%B4%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Логиканын мыйзамдары', 	material: 'https://eduhub.kg/assets/courseware/v1/3b141da52be08831281d360b33f6353d/asset-v1:KAI+INF85+01052021+type@asset+block/8_-_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.2._%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BD%D1%8B%D0%BD_%D0%BC%D1%8B%D0%B9%D0%B7%D0%B0%D0%BC%D0%B4%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмаларды чечүү', 	material: 'https://eduhub.kg/assets/courseware/v1/41c5063324950c3cf938ca52f2267a9c/asset-v1:KAI+INF85+01052021+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0%D1%8F_1.3._%D0%9B%D0%BE%D0%B3%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D1%82%D1%83%D1%8E%D0%BD%D1%82%D0%BC%D0%B0%D0%BB%D0%B0%D1%80%D0%B4%D1%8B_%D1%87%D1%8B%D0%B3%D0%B0%D1%80%D1%83%D1%83.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'ПК жана лицензиялардын түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/e767addf09a7f0bb0b10d601f731329e/asset-v1:KAI+INF86+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D0%9A_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D0%B4%D1%8B%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Компьютердик тармактар', 	material: 'https://eduhub.kg/assets/courseware/v1/c13a9d6459fdb6e52e6afcdc249bdb92/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.1._%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D1%82%D0%B0%D1%80%D0%BC%D0%B0%D0%BA%D1%82%D0%B0%D1%80.pdf' },
			{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдордун түрлөрү', 	material: 'https://eduhub.kg/assets/courseware/v1/4fe16e11a08fa880a2e22182863d3611/asset-v1:KAI+INF88+01092020+type@asset+block/8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.2._%D0%98%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82_%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D1%80%D0%B4%D1%83%D0%BD_%D1%82%D2%AF%D1%80%D0%BB%D3%A9%D1%80%D2%AF.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык сабаттуулук', 	material: 'https://eduhub.kg/assets/courseware/v1/8ef56db4fbb13906f8e98c26d4926297/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D1%81%D0%B0%D0%B1%D0%B0%D1%82%D1%82%D1%83%D1%83%D0%BB%D1%83%D0%BA.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Шифрлөө жана санарип кол тамга', 	material: 'https://eduhub.kg/assets/courseware/v1/cbc4cdab9542e6091e596d2d233100a2/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2_%D0%A8%D0%B8%D1%84%D1%80%D0%BB%D3%A9%D3%A9_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%AD%D0%A1%D0%9A%D0%A2.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Маалымат жана информатика', 	material_name: 'Графикалык маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/6dc99c7049d91bf1c7e598630aad36ee/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3.%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Компьютердик графика', 	material: 'https://eduhub.kg/assets/courseware/v1/e6ec0c8e782be3641ee5b716150448a5/asset-v1:KAI+INF86+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B02.1.%D0%9A%D0%9E%D0%9C%D0%9F%D0%AC%D0%AE%D0%A2%D0%95%D0%A0%D0%94%D0%98%D0%9A_%D0%93%D0%A0%D0%90%D0%A4%D0%98%D0%9A%D0%90.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Робототехникага киришүү', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%A0%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D0%B0%D0%B3%D0%B0%20%D0%BA%D0%B8%D1%80%D0%B8%D1%88%D2%AF%D2%AF.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Рекурсия', 	material: 'https://eduhub.kg/assets/courseware/v1/3eb6cfe2f0fac841be7eef7d7eb09759/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.1.%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%81%D0%B8%D1%8F.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Тизмелерди иштетүү алгоритмдери', 	material: 'https://eduhub.kg/assets/courseware/v1/a8703c2c9091dff51e437d639bd9634f/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%A2%D0%B8%D0%B7%D0%BC%D0%B5%D0%BB%D0%B5%D1%80%D0%B4%D0%B8_%D0%B8%D1%88%D1%82%D0%B5%D1%82%D2%AF%D2%AF_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D0%B4%D0%B5%D1%80%D0%B8.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Тизмелерди сорттоо', 	material: 'https://eduhub.kg/assets/courseware/v1/e3af850e351e10dc55d3ce4aa2fb3705/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.3.%D0%A2%D0%B8%D0%B7%D0%BC%D0%B5%D0%BB%D0%B5%D1%80%D0%B4%D0%B8_%D1%81%D0%BE%D1%80%D1%82%D1%82%D0%BE%D0%BE.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программалоо', 	material_name: 'Матрицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/e122c991d676edc2cda44bc4e0bf2139/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.4.%D0%9C%D0%B0%D1%82%D1%80%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Келечектин технологиясы', 	material: 'https://eduhub.kg/assets/courseware/v1/aa90bbc35ae6cffd76ebf6bc7f7b2201/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1._%D0%9A%D0%B5%D0%BB%D0%B5%D1%87%D0%B5%D0%BA%D1%82%D0%B8%D0%BD_%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D1%8B.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Санариптик дүйнөдөгү коопсуздук', 	material: 'https://eduhub.kg/assets/courseware/v1/ac37bdc540dae17f4d8dc614127588df/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%A1%D0%B0%D0%BD%D0%B0%D1%80%D0%B8%D0%BF_%D0%B4%D2%AF%D0%B9%D0%BD%D3%A9%D0%B4%D3%A9%D0%B3%D2%AF_%D0%BA%D0%BE%D0%BE%D0%BF%D1%81%D1%83%D0%B7%D0%B4%D1%83%D0%BA.pptx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык сабаттуулук', 	material: 'https://eduhub.kg/assets/courseware/v1/530c4fbe541f18dbf6701733951361df/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1._%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D1%81%D0%B0%D0%B1%D0%B0%D1%82%D1%82%D1%83%D1%83%D0%BB%D1%83%D0%BA.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Шифрлөө жана санарип кол тамга', 	material: 'https://eduhub.kg/assets/courseware/v1/1aea95aeaab166d0113a8cf161706d5c/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%A8%D0%B8%D1%84%D1%80%D0%BB%D3%A9%D3%A9_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%AD%D0%A1%D0%9A%D0%A2.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Маалымат жана информатика', 	material_name: 'Графикалык маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/f634a5654c4bd1f434adfc863f74ea89/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D0%B0%D1%80%D0%B4%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Рекурсия', 	material: 'https://eduhub.kg/assets/courseware/v1/ab4addbee07c71a27d1af3e6de652bf9/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.1.%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%81%D0%B8%D1%8F.docx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Тизмелерди иштетүү алгоритмдери', 	material: 'https://eduhub.kg/assets/courseware/v1/c71d2a3956218e6db7539edfbcdeacf3/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.2.%D0%9C%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%B4%D0%B5%D1%80%D0%B4%D0%B8_%D0%B8%D1%88%D1%82%D0%B5%D1%82%D2%AF%D2%AF_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D0%B4%D0%B5%D1%80%D0%B8.docx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Тизмелерди сорттоо', 	material: 'https://eduhub.kg/assets/courseware/v1/27e94d71eb400b7e6fe2a061b7b138aa/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.3.%D0%A2%D0%B8%D0%B7%D0%BC%D0%B5%D0%BB%D0%B5%D1%80%D0%B8_%D1%81%D0%BE%D1%80%D1%82%D1%82%D0%BE%D0%BE.docx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Программалоо', 	material_name: 'Матрицалар', 	material: 'https://eduhub.kg/assets/courseware/v1/b93403f2d2c8cba00b8cd860ec1c097f/asset-v1:KAI+INF87+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_3.4.%D0%9C%D0%B0%D1%82%D1%80%D0%B8%D1%86%D0%B0%D0%BB%D0%B0%D1%80__%D1%8D%D0%BA%D0%B8_%D3%A9%D0%BB%D1%87%D3%A9%D0%BC%D0%B4%D2%AF%D2%AF_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%B4%D0%B5%D1%80_.docx' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Келечектин технологиясы', 	material: 'https://eduhub.kg/assets/courseware/v1/6b0bfa65dd28491c8fc77e95357a75d4/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%9A%D0%B5%D0%BB%D0%B5%D1%87%D0%B5%D0%BA%D1%82%D0%B8%D0%BD_%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Сабактардын иштелмеси', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Санариптик дүйнөдөгү коопсуздук', 	material: 'https://eduhub.kg/assets/courseware/v1/a549b7cfff5fc16b43049477a73a8680/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%A1%D0%B0%D0%BD%D0%B0%D1%80%D0%B8%D0%BF%D1%82%D0%B8%D0%BA_%D0%B4%D2%AF%D0%B9%D0%BD%D3%A9%D0%B4%D3%A9%D0%B3%D2%AF_%D0%BA%D0%BE%D0%BE%D0%BF%D1%81%D1%83%D0%B7%D0%B4%D1%83%D0%BA.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык сабаттуулук', 	material: 'https://eduhub.kg/assets/courseware/v1/5e4b9855ee3e947747b4901b9304fb66/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%83%D1%81%D1%83%D0%BB_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE_1_%D0%B3%D0%BB%D0%B0%D0%B2%D0%B01.1_%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D1%81%D0%B0%D0%B1%D0%B0%D1%82%D1%82%D1%83%D1%83%D0%BB%D1%83%D0%BA.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Шифрлөө жана санарип кол тамга', 	material: 'https://eduhub.kg/assets/courseware/v1/ccfbf3149837937b1ae46b9c66a126fa/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%83%D1%81%D1%83%D0%BB%D0%B4%D1%83%D0%BA_%D0%BA%D0%BE%D0%BB%D0%B4%D0%BE%D0%BD%D0%BC%D0%BE_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%AD%D0%A1%D0%9A%D0%A2.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Маалымат жана информатика', 	material_name: 'Графикалык маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/fbfb324e5a6e005b0dcd7ab96aef00cc/asset-v1:KAI+INF85+01052021+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Компьютердик графика', 	material: 'https://eduhub.kg/assets/courseware/v1/5817b996124f56594e79bee248907b53/asset-v1:KAI+INF86+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%B4%D0%B8%D0%BA_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0_.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Робототехникага киришүү', 	material: 'https://eduhub.kg/assets/courseware/v1/93d1a0732053b94c258ed8835da47adc/asset-v1:KAI+INF86+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%A0%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D0%B0%D0%B3%D0%B0_%D0%BA%D0%B8%D1%80%D0%B8%D1%88%D2%AF%D2%AF.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Келечектин технологиясы', 	material: 'https://eduhub.kg/assets/courseware/v1/8def4d60e6df3ab0af4556b0b085697a/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1_%D0%A3%D0%9A%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%B5%D0%BB%D0%B5%D1%87%D0%B5%D0%BA%D1%82%D0%B8%D0%BD_%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Усулдук колдонмолор', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Санариптик дүйнөдөгү коопсуздук', 	material: 'https://eduhub.kg/assets/courseware/v1/89eb5c9325cc75367d8beef8841e072a/asset-v1:KAI+INF88+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%A3%D0%9A_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%A1%D0%B0%D0%BD%D0%B0%D1%80%D0%B8%D0%BF_%D0%B4%D2%AF%D0%B9%D0%BD%D3%A9%D0%B4%D3%A9%D0%B3%D2%AF_%D0%BA%D0%BE%D0%BE%D0%BF%D1%81%D1%83%D0%B7%D0%B4%D1%83%D0%BA.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык сабаттуулук', 	material: 'https://eduhub.kg/assets/courseware/v1/3b709edffa42c4eda0e563dc7e32bc09/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.1%D0%9C%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B%D0%BA_%D1%81%D0%B0%D0%B1%D0%B0%D1%82%D1%82%D1%83%D1%83%D0%BB%D1%83%D0%BA.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Шифрлөө жана санарип кол тамга', 	material: 'https://eduhub.kg/assets/courseware/v1/0c976e21b8ed55f64da75479c7b0b6a9/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.2._%D0%A8%D0%B8%D1%84%D1%80%D0%BB%D3%A9%D3%A9_%D0%B6%D0%B0%D0%BD%D0%B0_%D0%AD%D0%A1%D0%9A%D0%A2.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Үй тапшырмасы', 	course: 'Маалымат жана информатика', 	material_name: 'Графикалык маалыматты коддоо', 	material: 'https://eduhub.kg/assets/courseware/v1/9613e08c93a42942a707c59f1e7a03d6/asset-v1:KAI+INF85+01052021+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_1.3._%D0%93%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0%D0%BB%D1%8B%D0%BA_%D0%BC%D0%B0%D0%B0%D0%BB%D1%8B%D0%BC%D0%B0%D1%82%D1%82%D1%8B_%D0%BA%D0%BE%D0%B4%D0%B4%D0%BE%D0%BE.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Келечектин технологиясы', 	material: 'https://eduhub.kg/assets/courseware/v1/c4a49eb46aa6a6ff698d92d31c479f0f/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.1._%D0%9A%D0%B5%D0%BB%D0%B5%D1%87%D0%B5%D0%BA%D1%82%D0%B8%D0%BD_%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F%D0%BB%D0%B0%D1%80%D1%8B.pdf' },
			{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Үй тапшырмасы', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Санариптик дүйнөдөгү коопсуздук', 	material: 'https://eduhub.kg/assets/courseware/v1/bcb297c82d602a163ab43cc91b4b8f7d/asset-v1:KAI+INF88+01092020+type@asset+block/9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81%D1%82%D0%B0%D0%BF%D1%88%D1%8B%D1%80%D0%BC%D0%B0_4.2._%D0%A1%D0%B0%D0%BD%D0%B0%D1%80%D0%B8%D0%BF_%D0%B4%D2%AF%D0%B9%D0%BD%D3%A9%D1%81%D2%AF%D0%BD%D0%B4%D3%A9%D0%B3%D2%AF_%D0%BA%D0%BE%D0%BE%D0%BF%D1%81%D1%83%D0%B7%D0%B4%D1%83%D0%BA.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Компьютер в жизни человека', 	material: 'https://eduhub.kg/assets/courseware/v1/8cb925aa2e93c515eec6dab942e349a3/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.1%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B2_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8_%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Информационные процессы и хранение информации', 	material: 'https://eduhub.kg/assets/courseware/v1/1660822da8ad128a75af7f8a93251ea0/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.2.%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%8B_%D0%B8_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации', 	material: 'https://eduhub.kg/assets/courseware/v1/dc9570be4bf5cf38c05423b52b729017/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.3.%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Виды и состав программного обеспечения', 	material: 'https://eduhub.kg/assets/courseware/v1/deedbf22de435aea1a7e0120bd530a83/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.1_%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8_%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%BD%D0%BE%D0%B3%D0%BE_%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Электронные таблицы', 	material: 'https://eduhub.kg/assets/courseware/v1/979fe0ccf9b6db6e0a6b65e3e15577e7/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.2_%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B._docx.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы', 	material: 'https://eduhub.kg/assets/courseware/v1/d0cb3972d42cc2a593040c2867e48ee5/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.1.%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%BE%D0%B2%D1%8B%D0%B5_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов', 	material: 'https://eduhub.kg/assets/courseware/v1/f8960e7aa6aa989f5413b12b1e4cdefa/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.2.%D0%9A%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D1%8B_%D1%81%D0%B0%D0%B9%D1%82%D0%BE%D0%B2.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - относительные ссылки', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/2bdc4ae7f52555cce3e25f0b6225f1ee/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0_%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5_%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B8.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - абсолютные ссылки', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/620ce38a1b3f79b399b84ae3a704d7eb/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0_%D0%B0%D0%B1%D1%81%D0%BE%D0%BB%D1%8E%D1%82%D0%BD%D1%8B%D0%B5_%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B8.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - смешанные ссылки', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/4b1840ffb05a45c3f3cea370be97de38/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0_%D1%81%D0%BC%D0%B5%D1%88%D0%B0%D0%BD%D0%BD%D1%8B%D0%B5_%D1%81%D1%81%D1%8B%D0%BB%D0%BA%D0%B8.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - журнал успеваемости', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/84fe9413c05311bb6ecad3107fc10822/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0_%D0%B6%D1%83%D1%80%D0%BD%D0%B0%D0%BB_%D1%83%D1%81%D0%BF%D0%B5%D0%B2%D0%B0%D0%B5%D0%BC%D0%BE%D1%81%D1%82%D0%B8.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - расчёт комиссионных', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/762ee2eef3b1e19d72347dc0cc29a3ed/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%97%D0%B0%D0%B4%D0%B0%D1%87%D0%B0_%D1%80%D0%B0%D1%81%D1%87%D1%91%D1%82_%D0%BA%D0%BE%D0%BC%D0%B8%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - города Кырыгызстана', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/5e7207983ac8c647564eeb99ef078fec/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%A3%D1%80%D0%BE%D0%BA_4_%D0%B7%D0%B0%D0%B4%D0%B0%D1%87%D0%B01_%D0%93%D0%BE%D1%80%D0%BE%D0%B4%D0%B0_%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD%D0%B0.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Задачи', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Задача - сведения об учащихся', 	material: 'https://studio.eduhub.kg/assets/courseware/v1/c679995a943e4a1a57cc7ea0c3fc0906/asset-v1:KAI+INF82+01092020+type@asset+block/%D0%A3%D1%80%D0%BE%D0%BA_4_%D0%B7%D0%B0%D0%B4%D0%B0%D1%87%D0%B02_%D0%A1%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F_%D0%BE%D0%B1_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F.xlsx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации', 	material: 'https://eduhub.kg/assets/courseware/v1/4cfd09fc57c46ec86ecf5e8f869d93a8/asset-v1:KAI+INF81+01052021+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_1.3._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Электронные таблицы', 	material: 'https://eduhub.kg/assets/courseware/v1/ade9ac3660decff94fcb7ee5b747d05b/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.2._%D0%9A%D0%9F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Презентации', 	material: 'https://eduhub.kg/assets/courseware/v1/5de7394ccdf1678ab7ba40494cc80c1c/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.3._%D0%9A%D0%9F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы', 	material: 'https://eduhub.kg/assets/courseware/v1/ba8a884a32a5c826de9cad78617df815/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.1._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%BE%D0%B2%D1%8B%D0%B5_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов', 	material: 'https://eduhub.kg/assets/courseware/v1/55f07bf5642a2d6ea3929a1a621c82c6/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.2._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B8_%D0%BE%D0%B1%D0%BB%D0%B0%D1%87%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Электронная почта', 	material: 'https://eduhub.kg/assets/courseware/v1/55f07bf5642a2d6ea3929a1a621c82c6/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.2._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B8_%D0%BE%D0%B1%D0%BB%D0%B0%D1%87%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Компьютер в жизни человека', 	material: 'https://eduhub.kg/assets/courseware/v1/32e546188b79c5e01cafae3a8a582f26/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5._%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Информационные процессы и хранение информации', 	material: 'https://eduhub.kg/assets/courseware/v1/5e58a24e6bb3b9009503ed3521cb466c/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5.%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации', 	material: 'https://eduhub.kg/assets/courseware/v1/9e9c42d1dc0a8f5294598ca52c1939f2/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы', 	material: 'https://eduhub.kg/assets/courseware/v1/752eca249a28d244c92c2f9d8b101e6c/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов', 	material: 'https://eduhub.kg/assets/courseware/v1/585c2b61aefecd8bfc043f844a3e72a2/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Электронная почта', 	material: 'https://eduhub.kg/assets/courseware/v1/d739af5051943db22d656405f7dae87e/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Компьютер в жизни человека', 	material: 'https://eduhub.kg/assets/courseware/v1/8de1c39884dc983b145caa8298993660/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B2_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8_%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Информационные процессы и хранение информации', 	material: 'https://eduhub.kg/assets/courseware/v1/2843fb00eb24e8a6867721312622e9ce/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%8B_%D0%B8_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации', 	material: 'https://eduhub.kg/assets/courseware/v1/dc9570be4bf5cf38c05423b52b729017/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.3.%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Виды и состав программного обеспечения', 	material: 'https://eduhub.kg/assets/courseware/v1/315ead451d45e850f71a8b24e9713514/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8_%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%BD%D0%BE%D0%B3%D0%BE_%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Электронные таблицы', 	material: 'https://eduhub.kg/assets/courseware/v1/fc04e532e3f71dd3a1cf6f48e632c482/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Презентации', 	material: 'https://eduhub.kg/assets/courseware/v1/ad77a66797ed221b8d20a2a099924dcc/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.3.%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Введение в Python. Структура программы', 	material: 'https://eduhub.kg/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_1_%D0%A2%D0%B5%D0%BC%D0%B0_3.1_%D0%AF%D0%B7%D1%8B%D0%BA_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_Python.docx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Ввод и вывод данных в программах на языке Python.Линейный программы', 	material: 'https://eduhub.kg/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_2_%D0%A2%D0%B5%D0%BC%D0%B0_3.2._%D0%A2%D0%B8%D0%BF%D1%8B_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%BD%D0%B0%D0%B4_%D0%BD%D0%B8%D0%BC%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Условные операторы', 	material: 'https://eduhub.kg/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_3_%D0%A2%D0%B5%D0%BC%D0%B0_3.3._%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%8B.docx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с предусловием и постусловие', 	material: 'https://eduhub.kg/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_4_%D0%A2%D0%B5%D0%BC%D0%B0_3.4._%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B_while_%D0%B8_for.docx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы', 	material: 'https://eduhub.kg/assets/courseware/v1/b10e499e8b6040ae1bffd1564389e303/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%BE%D0%B2%D1%8B%D0%B5_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов', 	material: 'https://eduhub.kg/assets/courseware/v1/519d58e8293f661ba3bc8de6087ec449/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%9A%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D1%8B_%D1%81%D0%B0%D0%B9%D1%82%D0%BE%D0%B2.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Электронная почта', 	material: 'https://eduhub.kg/assets/courseware/v1/dbceb04ab7fc9faf4adc0f420a3f30f3/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3._%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B8_%D0%BB%D0%B1%D0%BB%D0%B0%D1%87%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B.pdf' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Компьютер в жизни человека', 	material: 'https://eduhub.kg/assets/courseware/v1/b1f3d948e878f610ac5bf4b5e834fc0a/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80_%D0%B2_%D0%B6%D0%B8%D0%B7%D0%BD%D0%B8_%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Информационные процессы и хранение информации', 	material: 'https://eduhub.kg/assets/courseware/v1/11a236d48f1283309a851eb20bc71c86/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2.%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D0%B5_%D0%BF%D1%80%D0%BE%D1%86%D0%B5%D1%81%D1%81%D1%8B_%D0%B8_%D1%85%D1%80%D0%B0%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации', 	material: 'https://eduhub.kg/assets/courseware/v1/11472ef91b723e0dbb1771ba56044d60/asset-v1:KAI+INF81+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3.%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%82%D0%B5%D0%BA%D1%81%D1%82%D0%BE%D0%B2%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Виды и состав программного обеспечения', 	material: 'https://eduhub.kg/assets/courseware/v1/11e72a9a1bf9f993886762d2f711e632/asset-v1:KAI+INF82+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.1.%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8_%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2_%D0%9F%D0%9E.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Введение в Python. Структура программы', 	material: 'https://eduhub.kg/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.1.%D0%AF%D0%B7%D1%8B%D0%BA_%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F_Python.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Ввод и вывод данных в программах на языке Python.Линейный программы', 	material: 'https://eduhub.kg/assets/courseware/v1/52fb4d31a6a34895be388522e3781d2e/asset-v1:KAI+INF83+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%A2%D0%B8%D0%BF%D1%8B_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D0%BD%D0%B0%D0%B4_%D0%BD%D0%B8%D0%BC%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Условные операторы', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/7%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.3.%D0%A3%D1%81%D0%BB%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5%20%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%82%D0%BE%D1%80%D1%8B.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с предусловием и постусловием', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/7%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.4.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B.%20%20%D0%A7%D0%B0%D1%81%D1%82%D1%8C%201.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B%20%D1%81%20%D0%BF%D1%80%D0%B5%D0%B4%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D0%B5%D0%BC.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с предусловием и постусловием(часть 2)', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/7%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.4.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B.%20%20%D0%A7%D0%B0%D1%81%D1%82%D1%8C%202.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B%20%D1%81%20%D0%BF%D0%BE%D1%81%D1%82%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D0%B5%D0%BC.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с предусловием и постусловием(часть 3)', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/7%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.4.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B.%20%20%D0%A7%D0%B0%D1%81%D1%82%D1%8C%203.%20%D0%A6%D0%B8%D0%BA%D0%BB%D1%8B%20%D1%81%20%D0%BF%D0%B0%D1%80%D0%B0%D0%BC%D0%B5%D1%82%D1%80%D0%BE%D0%BC.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы', 	material: 'https://eduhub.kg/assets/courseware/v1/e9dae4f3094f27756a97be8e9222e1a2/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1.%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5_%D0%BF%D0%BE%D0%B8%D1%81%D0%BA%D0%BE%D0%B2%D1%8B%D0%B5_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D1%8B.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов', 	material: 'https://eduhub.kg/assets/courseware/v1/922077d91457a11093f04cfb43585320/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%9A%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D1%8B_%D1%81%D0%B0%D0%B9%D1%82%D0%BE%D0%B2.pptx' },
			{ lang: 'Русский', 	grade: '7 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Электронная почта', 	material: 'https://eduhub.kg/assets/courseware/v1/3d5a8ead8377abcd94aedd1f440282ca/asset-v1:KAI+INF84+01092020+type@asset+block/7_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.3_%D0%AD%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%BF%D0%BE%D1%87%D1%82%D0%B0_%D0%B8_%D0%BE%D0%B1%D0%BB%D0%B0%D1%87%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции', 	material: 'https://eduhub.kg/assets/courseware/v1/294c5b81e98942e408b7901d56b9974e/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.1._%D0%9B%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Законы логики', 	material: 'https://eduhub.kg/assets/courseware/v1/6680dfd25388ed121d3dc15158f52b41/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.2._%D0%97%D0%B0%D0%BA%D0%BE%D0%BD%D1%8B_%D0%BB%D0%BE%D0%B3%D0%B8%D0%BA%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Решение логических выражений', 	material: 'https://eduhub.kg/assets/courseware/v1/cd0d2fb6d1fbfdf4aa0c7cabea486b79/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.3._%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Программное обеспечение и виды лицензий', 	material: 'https://eduhub.kg/assets/courseware/v1/d0d3c5966471acba4da4b5782fe0e42e/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.1_%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%9F%D0%9E_%D0%B8_%D0%B2%D0%B8%D0%B4%D1%8B_%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети', 	material: 'https://eduhub.kg/assets/courseware/v1/07119fd780a101cb09a32ff0dcfae881/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.1._%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Виды Интернет-протоколов', 	material: 'https://eduhub.kg/assets/courseware/v1/335340343b881dbf219cb613d2cada29/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.2._%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D0%B2.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Базы данных', 	material: 'https://eduhub.kg/assets/courseware/v1/224e74f8cc41dc7579b3c1108362c1fe/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.2._%D0%9A%D0%9F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%91%D0%B0%D0%B7%D1%8B_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Виды Интернет-протоколов', 	material: 'https://eduhub.kg/assets/courseware/v1/9e999a54ad47edb9e0a433c4d3abe5f0/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.2._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D0%B2.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Каскадные таблицы стилей', 	material: 'https://eduhub.kg/assets/courseware/v1/2a3490096dba0dcc3519241565fd02d9/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_4.3._%D0%9A%D0%9F_%D1%82%D0%B5%D0%BC%D0%B0_%D0%9A%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B_%D1%81%D1%82%D0%B8%D0%BB%D0%B5%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции', 	material: 'https://eduhub.kg/assets/courseware/v1/27e613f14fc5510d7ac3353b9ff31414/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B01._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Законы логики', 	material: 'https://eduhub.kg/assets/courseware/v1/b6ce4c9169223f52ec8841f139d2db2a/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5._%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Решение логических выражений', 	material: 'https://eduhub.kg/assets/courseware/v1/5731e2225b60f42bf0cae431fd89e5c6/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети', 	material: 'https://eduhub.kg/assets/courseware/v1/84336ab438a36a9b8d1a8def28cc95c9/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Виды Интернет-протоколов', 	material: 'https://eduhub.kg/assets/courseware/v1/8083b090b44b920701d7e3064cf42412/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Каскадные таблицы стилей', 	material: 'https://eduhub.kg/assets/courseware/v1/996a57e11e7b034675faed665d0b1938/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции', 	material: 'https://eduhub.kg/assets/courseware/v1/1c403cdbad8a92dfcbd40f5281d4ef12/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9B%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Законы логики', 	material: 'https://eduhub.kg/assets/courseware/v1/5ec781295d36a0083d0c58622763873e/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%97%D0%B0%D0%BA%D0%BE%D0%BD%D1%8B_%D0%BB%D0%BE%D0%B3%D0%B8%D0%BA%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Решение логических выражений', 	material: 'https://eduhub.kg/assets/courseware/v1/8e47c625b191ba1381c3aa731be299e6/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Программное обеспечение и виды лицензий', 	material: 'https://eduhub.kg/assets/courseware/v1/a569bd82c9bafc270636593ece0735fb/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9F%D0%9E_%D0%B8_%D0%B2%D0%B8%D0%B4%D1%8B_%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Базы данных', 	material: 'https://eduhub.kg/assets/courseware/v1/639a8e674b158a039a40264a69599f0d/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2.%D0%91%D0%B0%D0%B7%D1%8B_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Сложные условия and, or, not', 	material: 'https://eduhub.kg/assets/courseware/v1/05f8e84e3d6cc73d30188d876e0602fe/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_1_%D0%A2%D0%B5%D0%BC%D0%B0_3.1_%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5_%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F_and__or__not.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Списки, кортежи и словари', 	material: 'https://eduhub.kg/assets/courseware/v1/481aae9cb9767012279baf455c7213e9/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_2_%D0%A2%D0%B5%D0%BC%D0%B0_3.2._%D0%A1%D0%BF%D0%B8%D1%81%D0%BA%D0%B8__%D0%BA%D0%BE%D1%80%D1%82%D0%B5%D0%B6%D0%B8_%D0%B8_%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D1%80%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с параметром.', 	material: 'https://eduhub.kg/assets/courseware/v1/0834a1ddc6c094d9942fc0dec474305a/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_3_%D0%A2%D0%B5%D0%BC%D0%B0_3.3._%D0%A6%D0%B8%D0%BA%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D1%8B.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Вложенные циклы', 	material: 'https://eduhub.kg/assets/courseware/v1/c334251f285f2d01a256709c0beb240c/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_4_%D0%A2%D0%B5%D0%BC%D0%B0_3.4._%D0%92%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5_%D1%86%D0%B8%D0%BA%D0%BB%D1%8B.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Функции', 	material: 'https://eduhub.kg/assets/courseware/v1/43ce9367cdb48758d2a4d3d7283111f0/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_5_%D0%A2%D0%B5%D0%BC%D0%B0_3.5._%D0%A4%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Массивы', 	material: 'https://eduhub.kg/assets/courseware/v1/e9dc42642ee4fac66c05c555ba0171fb/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_6_%D0%A2%D0%B5%D0%BC%D0%B0_3.6._%D0%9C%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D1%8B__%D0%BE%D0%B4%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D1%8B_.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Строки и операции с ними.', 	material: 'https://eduhub.kg/assets/courseware/v1/72a3aea6e5a3f8e3a5a6283b3f76681b/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_7_%D0%A2%D0%B5%D0%BC%D0%B0_3.7._%D0%A1%D1%82%D1%80%D0%BE%D0%BA%D0%B8_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8_%D1%81_%D0%BD%D0%B8%D0%BC%D0%B8.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Форматирование строк', 	material: 'https://eduhub.kg/assets/courseware/v1/506e4d75b1ea5d5a870d94cec12707c8/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_8_%D0%A2%D0%B5%D0%BC%D0%B0_3.8._%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D1%81%D1%82%D1%80%D0%BE%D0%BA.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Работа с графикой в Python', 	material: 'https://eduhub.kg/assets/courseware/v1/25ac83263d5b38a4974d15bc66a1f473/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_9_%D0%A2%D0%B5%D0%BC%D0%B0_3.9._%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%B0_%D1%81_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%BE%D0%B9_%D0%B2_Python.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети', 	material: 'https://eduhub.kg/assets/courseware/v1/2480c8cb88d03773c2f2d936270b9627/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Виды Интернет-протоколов', 	material: 'https://eduhub.kg/assets/courseware/v1/a505a9c1c258928cc399864b9146d17d/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D0%B2.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Каскадные таблицы стилей', 	material: 'https://eduhub.kg/assets/courseware/v1/075ebb1506639f82678b32cc2eb8e831/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.3._%D0%9A%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B_%D1%81%D1%82%D0%B8%D0%BB%D0%B5%D0%B9.pdf' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции', 	material: 'https://eduhub.kg/assets/courseware/v1/2fa022663a54f0f85de26bf0ea3cbe00/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%9B%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F_%D0%B8_%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Законы логики', 	material: 'https://eduhub.kg/assets/courseware/v1/cb7e1cd85179ea339a802789b631c944/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2_%D0%97%D0%B0%D0%BA%D0%BE%D0%BD%D1%8B_%D0%BB%D0%BE%D0%B3%D0%B8%D0%BA%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Решение логических выражений', 	material: 'https://eduhub.kg/assets/courseware/v1/595ed7b7818b741f91aa3c4cfdfb38b2/asset-v1:KAI+INF81+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3_%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BB%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85_%D0%B2%D1%8B%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B9.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Программное обеспечение и виды лицензий', 	material: 'https://eduhub.kg/assets/courseware/v1/e6ca57af3068fd4ffe622e61a79fabfa/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.1.%D0%9F%D0%9E_%D0%B8_%D0%B2%D0%B8%D0%B4%D1%8B_%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Базы данных. СУБД', 	material: 'https://eduhub.kg/assets/courseware/v1/ec15c2fc432f20b81fd31112eff51a26/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%91%D0%B0%D0%B7%D1%8B_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85__1_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Обработка данных с использованием СУБД. Часть 1', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/1.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Обработка данных с использованием СУБД. Часть 2', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/2.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Поиск информации в базе данных при помощи языка запросов. Часть 1', 	material: 'https://eduhub.kg/assets/courseware/v1/957cf2af3183cc787e6050654d08704e/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%9F%D0%BE%D0%B8%D1%81%D0%BA_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B2_%D0%B1%D0%B0%D0%B7%D0%B5_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%BF%D1%80%D0%B8_%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%B8_%D1%8F%D0%B7%D1%8B%D0%BA%D0%B0_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D0%BE%D0%B2__%D1%87%D0%B0%D1%81%D1%82%D1%8C_1___1_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Поиск информации в базе данных при помощи языка запросов. Часть 2', 	material: 'https://eduhub.kg/assets/courseware/v1/4f11f532539a7e10f9e28ec45c8f5abd/asset-v1:KAI+INF82+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_2_%D1%82%D0%B5%D0%BC%D0%B02.2.%D0%9F%D0%BE%D0%B8%D1%81%D0%BA_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8_%D0%B2_%D0%B1%D0%B0%D0%B7%D0%B5_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85_%D0%BF%D1%80%D0%B8_%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%B8_%D1%8F%D0%B7%D1%8B%D0%BA%D0%B0_%D0%B7%D0%B0%D0%BF%D1%80%D0%BE%D1%81%D0%BE%D0%B2__%D1%87%D0%B0%D1%81%D1%82%D1%8C_2_.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Сложные условия and, or, not', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.1.%D0%A1%D0%BB%D0%BE%D0%B6%D0%BD%D1%8B%D0%B5%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%B8%D1%8F.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Списки, кортежи и словари', 	material: 'https://eduhub.kg/assets/courseware/v1/16faa0408eeba48a98e459ff679f0001/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%A1%D0%BF%D0%B8%D1%81%D0%BA%D0%B8__%D0%BA%D0%BE%D1%80%D1%82%D0%B5%D0%B6%D0%B8_%D0%B8_%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D1%80%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с параметром.', 	material: 'https://eduhub.kg/assets/courseware/v1/0834a1ddc6c094d9942fc0dec474305a/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_3_%D0%A2%D0%B5%D0%BC%D0%B0_3.3._%D0%A6%D0%B8%D0%BA%D0%BB%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5_%D0%B0%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D1%8B.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Вложенные циклы', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.4.%D0%92%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5%20%D1%83%D1%81%D0%BB%D0%BE%D0%B2%D0%BD%D1%8B%D0%B5%20%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D0%B8%20%D1%86%D0%B8%D0%BA%D0%BB%D1%8B.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Функции', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.5.%D0%A4%D1%83%D0%BD%D0%BA%D1%86%D0%B8%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Массивы', 	material: 'https://eduhub.kg/assets/courseware/v1/e9dc42642ee4fac66c05c555ba0171fb/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_6_%D0%A2%D0%B5%D0%BC%D0%B0_3.6._%D0%9C%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D1%8B__%D0%BE%D0%B4%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D1%8B_.docx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Строки и операции с ними. Форматирование строк', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/8%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.7-3.8.%D0%A1%D1%82%D1%80%D0%BE%D0%BA%D0%B8%20%D0%B8%20%D0%BE%D0%BF%D0%B5%D1%80%D0%B0%D1%86%D0%B8%D0%B8%20%D1%81%20%D0%BD%D0%B8%D0%BC%D0%B8.%20%D0%A4%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5%20%D1%81%D1%82%D1%80%D0%BE%D0%BA.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Работа с графикой в Python', 	material: 'https://eduhub.kg/assets/courseware/v1/0133dbf4be99187b87a69aa43282969f/asset-v1:KAI+INF83+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_3_%D1%82%D0%B5%D0%BC%D0%B03.9.%D0%A0%D0%B0%D0%B1%D0%BE%D1%82%D0%B0_%D1%81_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%BE%D0%B9.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети', 	material: 'https://eduhub.kg/assets/courseware/v1/76c7490d49355e08b22cc6424dfd456d/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Виды Интернет-протоколов', 	material: 'https://eduhub.kg/assets/courseware/v1/13fbeaf544a49b1c7db9af805956d912/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%92%D0%B8%D0%B4%D1%8B_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%BF%D1%80%D0%BE%D1%82%D0%BE%D0%BA%D0%BE%D0%BB%D0%BE%D0%B2.pptx' },
			{ lang: 'Русский', 	grade: '8 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Каскадные таблицы стилей', 	material: 'https://eduhub.kg/assets/courseware/v1/897ac441028b48d6173312378bd54fa5/asset-v1:KAI+INF84+01092020+type@asset+block/8_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.3._%D0%9A%D0%B0%D1%81%D0%BA%D0%B0%D0%B4%D0%BD%D1%8B%D0%B5_%D1%82%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D1%8B_%D1%81%D1%82%D0%B8%D0%BB%D0%B5%D0%B9.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Информационная грамотность', 	material: 'https://eduhub.kg/assets/courseware/v1/0fb15941d849bd3c6684f09ca32d6abb/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.1%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Шифрование и электронно-цифровая подпись', 	material: 'https://eduhub.kg/assets/courseware/v1/35d6fa6c1905d1ca0564090c072b0f72/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.2._%D0%A8%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B8_%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F_%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Информатика и информация', 	material_name: 'Кодирование графической информации', 	material: 'https://eduhub.kg/assets/courseware/v1/cdd4fe0b51834e82baf849730a5cb592/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_1.3._%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Компьютерная графика', 	material: 'https://eduhub.kg/assets/courseware/v1/8d3164934a1a1e3ee239129b8c096746/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.1_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%B0%D1%8F_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику', 	material: 'https://eduhub.kg/assets/courseware/v1/cda5eb002ecf309407a10152d1c84df4/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.2_%D0%92%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B2_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D1%83.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Технологии будущего', 	material: 'https://eduhub.kg/assets/courseware/v1/0584aa0e68ad583b6ddbcd3f7e62fc47/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.1._%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8_%D0%B1%D1%83%D0%B4%D1%83%D1%89%D0%B5%D0%B3%D0%BE.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Домашнее задание', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Безопасность в цифровом мире', 	material: 'https://eduhub.kg/assets/courseware/v1/9935528878685cf1138a750b7634bded/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F_4.2._%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C_%D0%B2_%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%BC_%D0%BC%D0%B8%D1%80%D0%B5.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику', 	material: 'https://eduhub.kg/assets/courseware/v1/6220ed66197865b1727bcc5024e228fd/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_2.2._%D0%9A%D0%9F_%D0%B4%D0%BB%D1%8F_%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F_%D0%BA_%D1%80%D0%B0%D0%B7%D0%B4%D0%B5%D0%BB%D1%83_%D0%92%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B2_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D1%83.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Компьютерный практикум', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику(часть 2)', 	material: 'https://eduhub.kg/assets/courseware/v1/b64eba4b1f13305c8c351cd0e808a722/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2_%D0%92%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B2_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D1%83._%D0%9A%D0%9F.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Информационная грамотность', 	material: 'https://eduhub.kg/assets/courseware/v1/89a080485c72c063469be987a4cd1028/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1.%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Шифрование и электронно-цифровая подпись', 	material: 'https://eduhub.kg/assets/courseware/v1/6b06ad12fe2ffb1db82ad8c787e86032/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Методические рекомендации', 	course: 'Информатика и информация', 	material_name: 'Кодирование графической информации', 	material: 'https://eduhub.kg/assets/courseware/v1/c2c2d9bdb5cdeef6db1b32614588b937/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_1_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0_%D0%B8_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D1%8F._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Технологии будущего', 	material: 'https://eduhub.kg/assets/courseware/v1/5f9142717c009415e59616d46746e03a/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Методические рекомендации', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Безопасность в цифровом мире', 	material: 'https://eduhub.kg/assets/courseware/v1/65dcf8265ae9715af69088abff5b06c4/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2_%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B5_%D0%BF%D0%BE%D1%81%D0%BE%D0%B1%D0%B8%D0%B5_%D0%93%D0%BB%D0%B0%D0%B2%D0%B0_4_%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D1%81%D0%B5%D1%82%D0%B8_%D0%B8_%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82._%D0%A4%D0%BE%D0%BD%D0%B4__%D0%A1%D0%BE%D1%80%D0%BE%D1%81-%D0%9A%D1%8B%D1%80%D0%B3%D1%8B%D0%B7%D1%81%D1%82%D0%B0%D0%BD_.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Информационная грамотность', 	material: 'https://eduhub.kg/assets/courseware/v1/2b70144f0ef6fd5d6ca803974a406bfa/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.1._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Шифрование и электронно-цифровая подпись', 	material: 'https://eduhub.kg/assets/courseware/v1/8ae07dbce87568939eaa6207661ee23d/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.2._%D0%A8%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B8_%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F_%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Информатика и информация', 	material_name: 'Кодирование графической информации', 	material: 'https://eduhub.kg/assets/courseware/v1/7cf6af380abd3f3dbb64c1caa85bb184/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_1.3._%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Компьютерная графика', 	material: 'https://eduhub.kg/assets/courseware/v1/8c0df4424f68eeb47a98795bdab81b7b/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.1.%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%B0%D1%8F_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA%D0%B0.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику', 	material: 'https://eduhub.kg/assets/courseware/v1/dfc24280213768ed1881bb22712789f8/asset-v1:KAI+INF82+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_2.2_%D0%92%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5_%D0%B2_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D1%83.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Рекурсия', 	material: 'https://eduhub.kg/assets/courseware/v1/fc742cc4e0baba91794caa62d35bdc26/asset-v1:KAI+INF83+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_1_%D0%A2%D0%B5%D0%BC%D0%B0_3.1._%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%81%D0%B8%D1%8F.docx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Обработка массивов', 	material: 'https://eduhub.kg/assets/courseware/v1/3ce024d10edf88c9f477ac6988de4203/asset-v1:KAI+INF83+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_2_%D0%A2%D0%B5%D0%BC%D0%B0_3.2._%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D1%8B_%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%BE%D0%B2.docx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Сортировка списков', 	material: 'https://eduhub.kg/assets/courseware/v1/74155f691f99c8906278e232ee0da1bb/asset-v1:KAI+INF83+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_3_%D0%A2%D0%B5%D0%BC%D0%B0_3.3._%D0%A1%D0%BE%D1%80%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B0_%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%BE%D0%B2.docx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Программирование', 	material_name: 'Матрицы', 	material: 'https://eduhub.kg/assets/courseware/v1/335d8e074c3a96d51f53d9c69f5c4f5e/asset-v1:KAI+INF83+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A3%D1%80%D0%BE%D0%BA_4_%D0%A2%D0%B5%D0%BC%D0%B0_3.4._%D0%9C%D0%B0%D1%82%D1%80%D0%B8%D1%86%D1%8B__%D0%B4%D0%B2%D1%83%D0%BC%D0%B5%D1%80%D0%BD%D1%8B%D0%B5_%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D1%8B_.docx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Технологии будущего', 	material: 'https://eduhub.kg/assets/courseware/v1/25b64808a209359c0dc06d50aa741dd7/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.1._%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8_%D0%B1%D1%83%D0%B4%D1%83%D1%89%D0%B5%D0%B3%D0%BE.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Поурочные планы', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Безопасность в цифровом мире', 	material: 'https://eduhub.kg/assets/courseware/v1/92135853bb4211eaa055fad1adb37640/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%A2%D0%B5%D0%BC%D0%B0_4.2._%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C_%D0%B2_%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%BC_%D0%BC%D0%B8%D1%80%D0%B5.pdf' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Информационная грамотность', 	material: 'https://eduhub.kg/assets/courseware/v1/53ef2438ee0e221b0ee77a5e9000e335/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.1.%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%B0%D1%8F_%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Шифрование и электронно-цифровая подпись', 	material: 'https://eduhub.kg/assets/courseware/v1/58e69e4fc719f5d025b790bc8112f867/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.2_%D0%A8%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B8_%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE-%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F_%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Кодирование графической информации', 	material: 'https://eduhub.kg/assets/courseware/v1/c0d1f09250a6ba5037df43581d37e647/asset-v1:KAI+INF81+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_1_%D1%82%D0%B5%D0%BC%D0%B01.3._%D0%9A%D0%BE%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5_%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9_%D0%B8%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%86%D0%B8%D0%B8.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Компьютерная графика', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9_2.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9_1.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Рекурсия', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.1.%D0%A0%D0%B5%D0%BA%D1%83%D1%80%D1%81%D0%B8%D1%8F.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Обработка массивов', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.2.%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%D1%8B%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8%20%D0%BC%D0%B0%D1%81%D1%81%D0%B8%D0%B2%D0%BE%D0%B2.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Сортировка списков', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.3.%D0%A1%D0%BE%D1%80%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B0%20%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%BE%D0%B2.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Программирование', 	material_name: 'Матрицы', 	material: 'https://eduhubbackup.eu-central-1.linodeobjects.com/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90%203_%D1%82%D0%B5%D0%BC%D0%B03.4.%D0%9C%D0%B0%D1%82%D1%80%D0%B8%D1%86%D1%8B.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Технологии будущего', 	material: 'https://eduhub.kg/assets/courseware/v1/b5e4a61bd737f744d4804c9719235f1a/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.1._%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8_%D0%B1%D1%83%D0%B4%D1%83%D1%89%D0%B5%D0%B3%D0%BE.pptx' },
			{ lang: 'Русский', 	grade: '9 класс', 	type: 'Презентация', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Безопасность в цифровом мире', 	material: 'https://eduhub.kg/assets/courseware/v1/095af05be36e2795fa871221a68ab2ef/asset-v1:KAI+INF84+01092020+type@asset+block/9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%93%D0%9B%D0%90%D0%92%D0%90_4_%D1%82%D0%B5%D0%BC%D0%B04.2._%D0%91%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D1%8C_%D0%B2_%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%BE%D0%BC_%D0%BC%D0%B8%D1%80%D0%B5.pptx' },
			{ lang: 'Русский', 	grade: 'Общие', 	type: 'Основные документы', 	course: 'Информатика и информация', 	material_name: 'Государственный образовательный стандарт школьного Общиеего образования Кыргызской Республики, утвержденный постановлением Правительства Кыргызской Республики от 21 июля 2014 года №403;', 	material: 'https://eduhub.kg/assets/courseware/v1/c6b10429c21fc5d854e2b55bacc14cf3/asset-v1:KAI+INF81+01092020+type@asset+block/%D0%93%D0%BE%D1%81%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82.PDF' },
{ lang: 'Русский', 	grade: 'Общие', 	type: 'Основные документы', 	course: 'Информатика и информация', 	material_name: 'Предметный стандарт по Информатике, утвержденный приказом министра образования и науки Кыргызской Республики №866/1 от 17 июля 2019 г.;', 	material: 'https://eduhub.kg/assets/courseware/v1/3b9fe73d726c4885534d6a3f990c44f5/asset-v1:KAI+INF81+01092020+type@asset+block/%D0%9F%D1%80%D0%B5%D0%B4%D0%BC%D0%B5%D1%82%D0%BD%D1%8B%D0%B9_%D1%81%D1%82%D0%B0%D0%BD%D0%B4%D0%B0%D1%80%D1%82_%D0%BF%D0%BE_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B5-5-9-%D1%80%D1%83%D1%81%D1%81.pdf' },
{ lang: 'Русский', 	grade: 'Общие', 	type: 'Основные документы', 	course: 'Информатика и информация', 	material_name: 'Учебная программа по предмету «Информатика»;', 	material: 'https://eduhub.kg/assets/courseware/v1/2f39d91806f498c98b74b1b924441ad6/asset-v1:KAI+INF81+01092020+type@asset+block/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0_%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0__%D0%BF%D0%BE-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%B8%D0%BA%D1%83-%D0%A4%D0%A1%D0%9A.pdf' },
{ lang: 'Русский', 	grade: 'Общие', 	type: 'Презентация', 	course: 'Информатика и информация', 	material_name: 'Презентация по темам предмета «Информатика» 7-9 классов и т.', 	material: 'https://eduhub.kg/assets/courseware/v1/92a06fc48713b99698bcb697525ca766/asset-v1:KAI+INF81+01092020+type@asset+block/%D0%9F%D1%80%D0%B5%D0%B7%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D0%B8_%D0%BF%D0%BE_%D1%82%D0%B5%D0%BC%D0%B0%D0%BC_%D0%BF%D1%80%D0%B5%D0%B4%D0%BC%D0%B5%D1%82%D0%B0__%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0__7-9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81%D0%BE%D0%B2.pdf' },
{ lang: 'Русский', 	grade: 'Общие', 	type: 'Учебник', 	course: 'Информатика и информация', 	material_name: '«Информатика» 7-9 класс (рус)', 	material: 'https://new.bizdin.kg/media/books/%D0%A3%D1%87%D0%B5%D0%B1%D0%BD%D0%B8%D0%BA._%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0._7-9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81._%D0%A0%D0%A8.pdf' },
{ lang: 'Кыргызский', 	grade: 'Жалпы', 	type: 'Окуу китеби', 	course: 'Маалымат жана информатика', 	material_name: '«Информатика» 7-9 класс (кырг)', 	material: 'https://new.bizdin.kg/kniga/uchebnik-informatika-7-9-klass-ksh-2019' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Кодирование текстовой информации. 7 класс. Тема 1.3', 	material: 'http://youtube.com/watch?v=6kvmsWkOi0k' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Компьютер в жизни человека. 7 класс. Тема 1.1.', 	material: 'http://youtube.com/watch?v=21Dl779jkXQ' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Электронные таблицы. 7 класс. 2.2', 	material: 'http://youtube.com/watch?v=D5y3mIyjaGQ' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции. 8 класс. Тема 1.1', 	material: 'http://youtube.com/watch?v=5FBoN3PtCZw' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Информационные процессы и хранение информации. 7 класс. Тема 1.2', 	material: 'http://youtube.com/watch?v=pylD4za2fj0' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Кодирование графической информации. 9 класс. Тема 1.3', 	material: 'http://youtube.com/watch?v=NZoJ8yxlIMM' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Введение в робототехнику. 9 Класс.', 	material: 'http://youtube.com/watch?v=px-jYWgpNj8' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Информационная грамотность. 9 класс. Тема 1.1', 	material: 'http://youtube.com/watch?v=ExFFv0DfnLk' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Тексттик маалыматты коддоо. 7 класс. 1.3', 	material: 'http://youtube.com/watch?v=yNpT3w1Y24I' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Законы логики. 8 класс. Тема 1.2', 	material: 'http://youtube.com/watch?v=4L0FJGgDVNw' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Шифрование и электронно цифровая подпись. 9 класс. Тема 1.2', 	material: 'http://youtube.com/watch?v=xW66Ep9e1qM' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Решение логических выражений. 8 класс. Тема 1.3', 	material: 'http://youtube.com/watch?v=mpPzxR68xog' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмалар жана амалдар. 8 класс. 1.1', 	material: 'http://youtube.com/watch?v=b_Bmfzm6dM8' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Стилдердин каскаддык таблицалары. 8 класс. 4.3', 	material: 'http://youtube.com/watch?v=uF24j1Wo0tI' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети. 8 класс. Тема 4.1', 	material: 'http://youtube.com/watch?v=0I-2imtCyzA' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Графикалык маалыматты коддоо. 9 класс. 1.3', 	material: 'http://youtube.com/watch?v=TWfnl0C2Jbk' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Виды и состав программного обеспечения. 7 КЛАСС. 2.1', 	material: 'http://youtube.com/watch?v=j3Nk786hxqA' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет протоколдорунун түрлөрү. 8 класс. 4.2', 	material: 'http://youtube.com/watch?v=lTDMUJ68mmE' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Келечектин технологиялары. 9 класс. 4.1', 	material: 'http://youtube.com/watch?v=0VarfzYcsjY' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: '', 	material_name: 'Типы данных и операции над ними. 7 класс', 	material: 'http://youtube.com/watch?v=47BxRjR3qYw' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Технологии будущего. 9 класс. Тема 4.1', 	material: 'http://youtube.com/watch?v=YA8moMLuemw' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Программное обеспечение и виды лицензий. 8 КЛАСС. 2.1', 	material: 'http://youtube.com/watch?v=QKmthReNLb0' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Сложные поисковые запросы. 7 класс. Тема 4.1', 	material: 'http://youtube.com/watch?v=weJiGKxoxdw' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Базы данных СУБД. 8 Класс.2 Раздел. 2 Урок.', 	material: 'http://youtube.com/watch?v=-ur9UUlW7sk' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Виды интернет протоколов. 8 класс. Тема 4.2', 	material: 'http://youtube.com/watch?v=Hb9xZEryGUQ' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Язык программирования Python. 7 класс.', 	material: 'http://youtube.com/watch?v=mSDQsHFjMyk' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Каскадные таблицы стилей. 8 класс. Тема 4.3', 	material: 'http://youtube.com/watch?v=HMf7wL_2LQ4' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык процесстер. 7 класс. Глава 1.2', 	material: 'http://youtube.com/watch?v=8j4h6jKirnU' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Информатика и информация', 	material_name: 'Логические выражения и операции. 8 класс. Тема 1.1', 	material: 'http://youtube.com/watch?v=TxA66fPY1Sw' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Безопасность в цифровом мире. 9 класс. Тема 4.2', 	material: 'http://youtube.com/watch?v=El664ryXGmk' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Конструкторы сайтов. 7 класс. Тема 4.2', 	material: 'http://youtube.com/watch?v=I2v0uaH6dxc' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Базы данных. 8 Класс. 2 Раздел.2 Урок', 	material: 'http://youtube.com/watch?v=QwEZLEqH3_I' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Массивдер. 8 класс.', 	material: 'http://youtube.com/watch?v=I_ToHQegyVo' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Алгоритмы обработки массивов. 9 класс. 3 раздел.', 	material: 'http://youtube.com/watch?v=kWhELplAovM' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Маалымат жана информатика', 	material_name: 'Логиканын мыйзамдары. 8 класс. 1.2', 	material: 'http://youtube.com/watch?v=1Cyhp694ZAE' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Компьютер адамдын турмушунда. 7 класс. Глава 1.1', 	material: 'http://youtube.com/watch?v=js032g6is6E' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Санарип дүйнөдөгү коопсуздук. 9 класс. 4.2', 	material: 'http://youtube.com/watch?v=80DubBhKexQ' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Логикалык туюнтмаларды чыгаруу. 8 класс. 1.3', 	material: 'http://youtube.com/watch?v=2itjF4MW3EI' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Татаал издоо суроо талаптары. 7 класс. 4.1', 	material: 'http://youtube.com/watch?v=4Ar45Z6plFU' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Интернет тармактары. 8 класс. 4.1', 	material: 'http://youtube.com/watch?v=Ir_LGupZ7gc' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Обработка данных с использованием СУБД Часть 1. 8 Класс. 2 Раздел 3 Урок', 	material: 'http://youtube.com/watch?v=RbmYWXgZSlM' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Шифрлөө жана ЭСКТ. 9 класс. 1.2', 	material: 'http://youtube.com/watch?v=58LnIqU2gcg' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Электронная почта и облачные сервисы. 7 класс. Тема 4.3', 	material: 'http://youtube.com/watch?v=cxv-vhcl-rc' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'МАТРИЦЫ. 9 КЛАСС. 3 РАЗДЕЛ.', 	material: 'http://youtube.com/watch?v=qyvoT7trX4E' },
{ lang: 'Русский', 	grade: '', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Функции часть 1. Класс 8', 	material: 'http://youtube.com/watch?v=m4z8hIoxDX8' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Маалымат жана информатика', 	material_name: 'Маалыматтык сабаттуулук. 9 класс. 1.1', 	material: 'http://youtube.com/watch?v=IhVrkYxegW8' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Организация вычислений в электронных таблицах(2 часть). 7 класс', 	material: 'http://youtube.com/watch?v=FoHgrTQj370' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Работа с графикой в Python. 8 класс', 	material: 'http://youtube.com/watch?v=-e5P_9cWCII' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Организация вычислений в электронных таблицах(1 часть). 7 класс', 	material: 'http://youtube.com/watch?v=Xy49NqXorHA' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Поиск информации в базе данных при помощи языка запросов . Часть 1. 8 Класс 2 Раздел. 5 Урок', 	material: 'http://youtube.com/watch?v=0GNWqZmfVqI' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Функциялар. 8 класс.', 	material: 'http://youtube.com/watch?v=PTskg-M2V-I' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Сайт түзүүнүн кострукторлору. 7 класс. 4.2', 	material: 'http://youtube.com/watch?v=p06aehuVRtc' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Саптар жана алар менен болгон амалдар. 8 класс.', 	material: 'http://youtube.com/watch?v=KasMSrAmaHM' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Робототехникага киришүү. 9 класс. 2 глава', 	material: 'http://youtube.com/watch?v=PaHPaSlDRWQ' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютерные сети и Интернет', 	material_name: 'Компьютерные сети. 8 класс. Тема 4.1', 	material: 'http://youtube.com/watch?v=lBit0664xF4' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Программалык камсыздоонун түрлөрү жана курамы. 7 класс. ГЛАВА 2.', 	material: 'http://youtube.com/watch?v=5A9fvnBVz6Y' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Маалыматтар тиби жана алар менен болгон амалдар. 7 класс. 3 бөлүм.', 	material: 'http://youtube.com/watch?v=yhVIvZvlH5I' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Сложные условия. 8 класс.', 	material: 'http://youtube.com/watch?v=HodW5gghwlM' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Массивы. 8 класс', 	material: 'http://youtube.com/watch?v=dr5Pmjtv__s' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'РЕКУРСИЯ. 9 КЛАСС. 3 РАЗДЕЛ.', 	material: 'http://youtube.com/watch?v=wbwMbBBhE5k' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютердик желелер жана Интернет', 	material_name: 'Электрондук почта жана булуттук сервистер. 7 класс. 4.3', 	material: 'http://youtube.com/watch?v=5XjbR4WceRQ' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Рекурсия. 9 класс.', 	material: 'http://youtube.com/watch?v=bf0jq_Iaxtk' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Компьютерная графика .9 Класс', 	material: 'http://youtube.com/watch?v=vXwQ7ZKTg_E' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Обработка данных с использованием СУБД Часть 2. 8 Класс. 2 Раздел 4 Урок', 	material: 'http://youtube.com/watch?v=veSDBrfYkFA' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Компьютер и программное обеспечение', 	material_name: 'Обработка данных с использованием СУБД Часть 2. 8 Класс. 2 Раздел 4 Урок.', 	material: 'http://youtube.com/watch?v=hPmGBX5I7sI' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Камтылган шарттуу амалдар жана циклдер. 8 класс.', 	material: 'http://youtube.com/watch?v=KbdfZqrbKhs' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с параметром. Вложенные циклы. 8 класс', 	material: 'http://youtube.com/watch?v=yreFUVuldm4' },
{ lang: 'Русский', 	grade: '', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Функции часть 2. Класс 8', 	material: 'http://youtube.com/watch?v=Cc5TFxA3u64' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Циклические алгоритмы. Циклы с параметром. Вложенные циклы. 8 класс', 	material: 'http://youtube.com/watch?v=LpPJ5-67yO0' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Списки, кортежи и словари. 8 класс', 	material: 'http://youtube.com/watch?v=11VxQtYZLiI' },
{ lang: 'Русский', 	grade: '8 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Строки и операции с ними. Форматирование строк. 8 класс', 	material: 'http://youtube.com/watch?v=df4ROEgDsZk' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Тизмелерди иштетүү алгоритмдери. 9 класс.', 	material: 'http://youtube.com/watch?v=xsEeYVnoWlw' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Условные операторы в Python. 7 класс', 	material: 'http://youtube.com/watch?v=JeOnj4pHpJg' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Компьютердик графика. 9 класс. 2 глава.', 	material: 'http://youtube.com/watch?v=kVarII_BuDQ' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Циклы в Python Часть 2. 7 класс', 	material: 'http://youtube.com/watch?v=4iADAKcM52c' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Циклы в Python Часть 3. 7 класс', 	material: 'http://youtube.com/watch?v=YaaujTsdFts' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Python тилинде графика менен иштөө. 8 класс.', 	material: 'http://youtube.com/watch?v=5MNGKfBGYN8' },
{ lang: 'Русский', 	grade: '7 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'Циклы в Python Часть 1. 7 класс', 	material: 'http://youtube.com/watch?v=J1nWtCWRoTQ' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Электрондук таблицалар. 7 класс. ГЛАВА 2.', 	material: 'http://youtube.com/watch?v=sebQbBMU_7w' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Презентациялар. 7 класс. ГЛАВА 2.', 	material: 'http://youtube.com/watch?v=88AF1zCmMQU' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'ПК жана лицензиянын түрлөрү. 8 класс. ГЛАВА 2', 	material: 'http://youtube.com/watch?v=qg6eplyfHTA' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Компьютер жана програмдык жабдуулар', 	material_name: 'Маалыматтар базасы. 8 класс. ГЛАВА 2.', 	material: 'http://youtube.com/watch?v=1nuacsFyMHw' },
{ lang: 'Русский', 	grade: '9 класс', 	type: 'Видеоурок', 	course: 'Программирование', 	material_name: 'СОРТИРОВКА СПИСКОВ. 9 КЛАСС. 3 РАЗДЕЛ.', 	material: 'http://youtube.com/watch?v=7vJujAnGBgo' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Саптарды форматтоо. 8 класс.', 	material: 'http://youtube.com/watch?v=EkaahbQVdXI' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Матрицалар. 9 класс.', 	material: 'http://youtube.com/watch?v=o0Ld2TS8rqA' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Шарттуу операторлор. 7 класс. 3 бөлүм.', 	material: 'http://youtube.com/watch?v=6Tn2E8LjEWw' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'While жана for операторлору. 7 класс. 3 бөлүм.', 	material: 'http://youtube.com/watch?v=Otz3GGxFbKo' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Циклдик алгоритмдер. 8 класс.', 	material: 'http://youtube.com/watch?v=RSzUMNcuxOo' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Татаал шарттар and or not. 8 класс.', 	material: 'http://youtube.com/watch?v=Jor45MffsOk' },
{ lang: 'Кыргызский', 	grade: '8 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Тизмелер, кортеждер жана сөздүктөр. 8 класс.', 	material: 'http://youtube.com/watch?v=QKuJDfHCQmQ' },
{ lang: 'Кыргызский', 	grade: '9 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Тизмелерди сорттоо. 9 класс.', 	material: 'http://youtube.com/watch?v=SxAg_Z1bC7I' },
{ lang: 'Кыргызский', 	grade: '7 класс', 	type: 'Видеосабак', 	course: 'Программалоо', 	material_name: 'Python программалоо тили. 7 класс. 3 бөлүм', 	material: 'http://youtube.com/watch?v=nR8Ssznxduo' },

		]}    
		options={{
			search: true,
			pageSize:10,
			pageSizeOptions:[10,15,20,50],
			headerStyle:{fontWeight:'bold'}
		}}
		title="Курстун материалдары(Материалы курса)"
		            localization={{
		toolbar: {
		    searchTooltip: 'Издөө/Поиск',
                    searchPlaceholder: 'Издөө/Поиск'
		}
			    }}
		/>
		</div>
	);
}

export default App;
